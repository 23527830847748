
.u-cursor-pt {
  pointer-events: auto !important;
}

.annotation-edit {
  position: absolute;
  width: 40px;
  height: 20px;
  border: 1px solid red;
  background: white;
  margin-left: -20px;
  margin-top: -20px;
}

.annotation-gap {
  text-align: center;
  position: absolute;
  width: 100px;
  height: 20px;
  background: white;
  margin-left: -50px;
  margin-top: -20px;
  font-size: 12px;
}

tr.chartRendered {
  background: lightpink;
}

.annotation-edit[annotation-value="N"] {
  border-color: lightgray;
  z-index: 1000;
}

.annotation-edit[annotation-value="S"] {
  border-color: red;
  z-index: 1001;
}
.annotation-edit[annotation-value="V"] {
  border-color: red;
  z-index: 1001;
}
.annotation-edit[annotation-value="F"] {
  border-color: red;
  z-index: 1001;
}
.annotation-edit[annotation-value="U"] {
  border-color: red;
  z-index: 1001;
}

.ruler {
  display: flex;
  height: 100%;
  align-items: center;
  opacity: 0.5;
}

/* ecg interval styles - start */
.ruler.ecg {
  opacity: 1;
}
.ruler.ecg :first-child,
.ruler.ecg :last-child {
  background: #5B2C6F;
  height: 2px;
  flex-grow: 1;
}
.ruler.ecg :nth-child(2) {
  border: 1px solid #5B2C6F;
  background: white;
  font-size: 12px;
}
/* ecg interval styles - end */

.ruler :first-child,
.ruler :last-child {
  background: green;
  height: 1px;
  flex-grow: 1;
}

.ruler :nth-child(2) {
  font-size: 12px;
}

/* noise interval styling */
.ruler-noise {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.33);
}

.ruler-noise .bar {
  height: 100%;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.10);
  cursor: grab;
}

.ruler-noise .content {
  margin-top: -40px;
  border: 1px solid;
  background: white;
  text-align: center;
}

/* pause episode interval styling */
.ruler-pause {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(201, 255, 196, 0.762);
}

.ruler-pause .content {
  border: 1px solid;
  background: white;
  text-align: center;
}

.u-annotate {
  background: rgba(255, 0, 0, 0.3);
}

/* Afib interval styling */
.ruler-afib {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: rgba(203, 16, 16, 0.291);
}

.ruler-afib.ecg {
  background-color: rgba(237, 230, 15, 0.454);
}

.ruler-afib.ecg .content {
  margin-top: -20px;
}

.ruler-afib .content {
  margin-top: -40px;
  border: 1px solid;
  background: white;
  text-align: center;
}